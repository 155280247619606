<template>
    <modal ref="modalSubirImagen" titulo="Subir Imagen" no-aceptar adicional="Guardar" @adicional="postImagen()">
        <div class="row mx-0 my-4 justify-center">
            <cargando v-if="cargando" />
            <slim-cropper ref="imagen" :options="slimOptions" class="border" style="cursor:pointer;height:215px;background:#F8F9FF;width:380px;border-radius:12px;" />
        </div>
    </modal>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    props:{
        tipo:{
            type: Number,
            default: 0,
        }
    },
    data(){
        return {
            cargando: false,
            slimOptions: {
                ratio: '16:9',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSubirImagen.toggle();
        },
        async postImagen(){
            try {
                const params = {
                    tipo: this.tipo,
                    dimension: 1700,
                    imagen: this.$refs.imagen.get_image(),
                };
                if(params.imagen == null) return this.notificacion('Mensaje', 'Para continuar, por favor seleccione una imagen.','warning');
                this.cargando = true;
                const {data} = await Service.postImagen(params);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar');
                this.limpiar();
            } catch(error){
                this.error_catch(error)
            } finally{
                this.cargando = false;
            }
        },
        async limpiar(){
            this.descripcion = null;
            this.$refs.imagen.instanciaCrop.remove();
        },
    }
}
</script>
